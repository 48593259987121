import React from 'react';
import { Checkbox } from 'react-materialize';
import AddIcon from '@mui/icons-material/Add'; 

import '../styles/lookup.scss';

export default class RecipientSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tempEmail: ''
    };
  }

  validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  buildContactsList() {
    let contactsList = this.props.contacts.map(c => c.email);
    let tempEmails = this.props.selectedRecipients?.filter(r => !contactsList.includes(r));
    return this.props.contacts?.concat(tempEmails?.map(r => ({email: r})));
  }

  render() {
    return (
      <div className="modal-backdrop" onClick={async (e) => {
        e.stopPropagation();
        if (this.validateEmail(this.state.tempEmail)) {
          await this.props.addNewContact(this.state.tempEmail);
          this.props.handleRecipientSelect(this.state.tempEmail);
        } else {
          this.setState({tempEmail: ''});
          this.props.close();
        }
      }}>
        {/* <span style={{position: 'absolute', top: 0, left: 0, color: 'white'}}>{JSON.stringify(this.props.contacts.map(c => c.email))}</span> */}
        <div className="modal" style={{width: '500px'}} onClick={(e) => e.stopPropagation()}>
          <div className="modal-content recipient-select-modal-content">
            <h4>Select Recipients</h4>
            {this.buildContactsList().filter(Boolean).map(contact => (
              <div className="recipient-select-row" onClick={() => this.props.handleRecipientSelect(contact.email)}>
                <div className="recipient-select-row-checkbox">
                  <Checkbox 
                    checked={this.props.selectedRecipients?.includes(contact.email)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div className="recipient-select-row-info">
                  <span><b>{contact.fname} {contact.lname}</b></span>
                  <span style={{color: 'grey'}}>{contact.email}</span>
                </div>
              </div>
            ))}
            <div className='recipient-select-row'>
              <div className={`recipient-select-add-icon ${this.validateEmail(this.state.tempEmail) ? '' : 'disabled'}`}>
                <AddIcon 
                  style={{fontSize: 30}}
                  onClick={async () => {
                    if (this.validateEmail(this.state.tempEmail)) {
                      await this.props.addNewContact(this.state.tempEmail);
                      this.setState({tempEmail: ''});
                    }
                  }}
                />
              </div>
              <input
                type="email"
                placeholder="contact@example.com"
                style={{height: '30px'}}
                onChange={(e) => {
                  this.setState({tempEmail: e.target.value});
                }}
                value={this.state.tempEmail}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && this.validateEmail(e.target.value)) {
                    this.props.handleRecipientSelect(e.target.value);
                    this.setState({tempEmail: ''});
                  }
                }}
              />
            </div>
            <div className="recipient-select-row-buttons">
              <button className="quote-wizard-button btn btn-secondary" onClick={() => this.props.close()}>Cancel</button>
              <button 
                className="quote-wizard-button btn btn-primary" 
                onClick={async () => {
                  if (this.validateEmail(this.state.tempEmail)) {
                    await this.props.addNewContact(this.state.tempEmail);
                  }
                  this.props.submit();
                  this.setState({tempEmail: ''});
                }}
              >{this.props.loading ? this.props.loadingMessage : 'Continue'}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

