import React from 'react';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions

import '../styles/lookup.scss';
// import 'materialize-css';
import MapView from './MapView';
import ServiceLogModal from './ServiceLogModal';
import QuoteGeneratorModal from './QuoteGeneratorModal';
import AccountReviewModal from './AccountReviewModal';
import Search from './Search';
import Dropzone, {useDropzone} from 'react-dropzone';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Chart } from 'chart.js/auto';
import Checkbox from '@mui/material/Checkbox';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import HvacIcon from '@mui/icons-material/Hvac';
import BookIcon from '@mui/icons-material/Book';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ScienceIcon from '@mui/icons-material/Science';
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';
import FileIcon from '@mui/icons-material/FilePresent';
import { flexbox } from '@mui/system';


class AccountInfo extends React.Component {

  constructor() {
    super();

    this.onDrop = (files) => {
      this.setState({files});
    }

    this.state = {
      showServiceLogs: false,
      selectedFile: [],
      accountReviewChart: null,
      accountReviewYear: null,
      productsPage: 0,
      productsPerPage: 10,
      productSearch: '',
      ordersPage: 0,
      ordersPerPage: 10,
      quotesPage: 0,
      quotesPerPage: 10,
      tempContact: null
    };

    this.chart = null;
  }

  filterProducts() {
    if (!this.props.info?.products) return [];
    return this.props.info.products.filter(product => 
      product.model.toLowerCase().includes(this.state.productSearch.toLowerCase()) ||
      product.description.toLowerCase().includes(this.state.productSearch.toLowerCase())
    );
  }

  createOrUpdateChart() {
    let review = {};
    this.props.info?.account_review?.forEach(d => {
      if (!review[d.year]) {
        review[d.year] = {
          revenue: 0,
          cost: 0
        }
      } 
      review[d.year].revenue += parseFloat(d.sales) || 0;
      review[d.year].cost += parseFloat(d.cost) || 0;
    });

    const ctx = document.getElementById('accountReviewChart');
    if (ctx && this.props.info?.account_review) {
      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: Object.keys(review),
          datasets: [
            {
              label: 'Cost',
              data: Object.values(review).map(r => r.cost),
              backgroundColor: '#0D74FF'
            },
            {
              label: 'Sales',
              data: Object.values(review).map(r => r.revenue),
              backgroundColor: 'rgba(54, 162, 235)'
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          resizeDelay: 0,
          scales: {
            x: {
              reverse: true,
              stacked: true,
            },
            y: {
              beginAtZero: true,
            }
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const element = elements[0];
              const year = Object.keys(review)[element.index];
              this.setState({accountReviewYear: year});
            }
          }
        }
      });
    }
  }

  componentDidMount() {
    this.createOrUpdateChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.info?.account_review !== this.props.info?.account_review) {
      this.createOrUpdateChart();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  newContact() {
    let infoCopy = {...this.props.info};

    let id = Date.now();

    let newContact = {
      id: id, 
      fname: '',
      lname: '',
      title: '',
      phone: '',
      email: '',
      reports: false,
      primary: false,
      new: true,
      customer_id: this.props.info.id
    };

    infoCopy.contacts.push(newContact);

    this.props.updateLookupInfo(infoCopy);

    this.setState({tempContact: newContact});
  }

  newReport() {
    this.props.createReport(
      this.props.info.id, 
      'sr', 
      null, 
      this.props.authToken, 
      (newReportUrl) => {window.location.href = `/reports/edit?${newReportUrl}`}
    );
  }

  handleFileSubmit(file) {
    if (file) {
      // Validate file size
      const maxSize = 10 * 1024 * 1024; // 10MB

      if (file.size > maxSize) {
        console.error('File is too large. Maximum size is 10MB.');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('customer_id', this.props.info.id);
      formData.append('enctype', 'multipart/form-data');

      this.props.fileUpload(this.props.authToken, formData).then(() => {
        console.log('file uploaded');
      });
    }
  };  

  deleteFile(fileId) {
    this.props.deleteFile(this.props.authToken, fileId);
  }

  newQuote() {
    this.props.newQuote(
      this.props.authToken, 
      this.props.info.id,
      !this.props.quotableProducts?.length > 0
    );
  }

  loadQuote(id) {
    this.props.loadQuote(
      this.props.authToken, 
      id, 
      !this.props.quotableProducts?.length > 0
    );
  }

  render() {
    return this.props.info?.type === 'account' ? (
      <div className="info-container">
        <div className='account-header'>
          <div className='account-name'>{this.props.info.name}</div>
          {this.props.info.addresses.length} address{this.props.info.addresses.length > 1 ? 'es' : ''} on file
        </div>

        {this.props.info.addresses ? (
          <div className="map-container info-card">
            <MapView addresses={this.props.info.addresses} equipment={this.props.info.equipment}/>
          </div>
        ) : null}

        {this.props.info.account_review ? (
          <div className="info-card">
            <div className="card-header">Account Review (Click a bar to view yearly breakdown)</div>
            <div className="card-content">
              <canvas id="accountReviewChart"></canvas>
            </div>
          </div>
        ) : null}

        {this.props.info.equipment ? (
          <div 
            id="contract-systems" 
            className={`info-card expandable ${this.state.expanded === 'systems' ? 'expanded' : ''}`} 
            onClick={() => this.setState({expanded: 'systems'})}
          >
            <span className="card-header">
              <b>{this.props.info.equipment.length}</b>
              <span>System{this.props.info.equipment.length === 1 ? '' : 's'} under contract</span>
              <div className="card-icon-container">
                <HvacIcon className="card-icon"/>
              </div>
            </span>

            <div className="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {this.props.info.equipment.map((row) => (
                      <TableRow
                        key={'system-'+row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">
                          <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', flexDirection: 'column', marginRight: 5}}>
                              <span><b>{row.description}</b></span>
                              <span>Type: <b>{row.type}</b></span>
                            </div> 
                            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                              <span style={{textAlign: 'end'}}>Serviced <b>{row.frequency}</b></span>       
                            </div>
                          </div>
                        </TableCell>
                        {/* <TableCell align='right'>Serviced <b>{row.frequency}</b></TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : null}

        {this.props.info.reports ? (
          <div id="submitted-reports" className="info-card expandable">
            <span className="card-header">
              <b>{this.props.info.reports.length}</b>
              <span>
                Report{this.props.info.reports.length === 1 ? '' : 's'} submitted
              </span>

              <div className="card-icon-container">
                <ListAltIcon className="card-icon" />
              </div>
            </span>

            <div className="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="last 10 reports">
                  <TableBody>
                    {this.props.info.reports.reverse().map((report) => (
                      <TableRow
                        key={'report-'+report.url}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => window.open(report.url, '_blank', 'noopener,noreferrer')}
                      >
                        <TableCell align='left'><b>{report.title}</b></TableCell>
                        {report.submitted ? (
                          <TableCell align='right'>Submitted: <b>{report.created}</b></TableCell>
                        ) : (
                          <TableCell align='right'><b>** DRAFT **</b></TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='add-new' onClick={() => this.newReport()}>
                <AddIcon style={{color: '#0D74FF', marginRight: 50, zIndex: 1}}/>
                <span>Create New Report</span>
              </div>
            </div>
          </div>      
        ) : null}
        
        {this.props.info.products ? (
          <div id="products-ordered" className="info-card expandable">
            <span className="card-header">
              <b>{this.props.info.products.length}</b>
              <span>Product{this.props.info.products.length === 1 ? '' : 's'} used</span>
              <div className="card-icon-container">
                <ScienceIcon className="card-icon" />
              </div>
            </span>

            <div className="card-content">
              <div style={{ 
                padding: '0 0 5px 0',
                display: 'flex',
                alignItems: 'center'
              }}>
                <input
                  type="text"
                  placeholder={`Search products used at ${this.props.info.name}`}
                  value={this.state.productSearch}
                  onChange={(e) => this.setState({ productSearch: e.target.value, productsPage: 0 })}
                  className="search-input"
                />
              </div>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium">
                  <TableBody>
                    {this.filterProducts()
                      .slice(
                        this.state.productsPage * this.state.productsPerPage,
                        (this.state.productsPage + 1) * this.state.productsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          key={'product-'+row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { padding: '10px 16px' } }}
                          onClick={() => {this.props.fetchInfo(this.props.authToken, row.id, 'p', null)}}
                        >
                          <TableCell align='center' size='medium' sx={{width: '30%'}}><b>{row.model}</b></TableCell>
                          <TableCell align='left' sx={{width: '70%'}}>{row.description}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                <button 
                  onClick={() => this.setState({ productsPage: Math.max(0, this.state.productsPage - 1) })}
                  disabled={this.state.productsPage === 0}
                  style={{ marginRight: '1rem' }}
                >
                  Previous
                </button>
                <span>
                  Page {this.state.productsPage + 1} of{' '}
                  {Math.ceil(this.filterProducts().length / this.state.productsPerPage)}
                </span>
                <button 
                  onClick={() => this.setState({ 
                    productsPage: Math.min(
                      Math.ceil(this.filterProducts().length / this.state.productsPerPage) - 1,
                      this.state.productsPage + 1
                    )
                  })}
                  disabled={this.state.productsPage >= Math.ceil(this.filterProducts().length / this.state.productsPerPage) - 1}
                  style={{ marginLeft: '1rem' }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : null}
        
        {this.props.info.orders ? (
          <div id="orders-submitted" className="info-card expandable">
            <span className="card-header">
              <b>{this.props.info.orders.length}</b>
              <span>
                Order{this.props.info.orders.length === 1 ? '' : 's'} placed since {new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString()}
              </span>

              <div className="card-icon-container">
                <ShoppingCartIcon className="card-icon" />
              </div>
            </span>

            <div className="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium">
                  <TableBody>
                    {[...this.props.info.orders]
                      .sort((a, b) => new Date(b.submitted) - new Date(a.submitted))
                      .slice(
                        this.state.ordersPage * this.state.ordersPerPage,
                        (this.state.ordersPage + 1) * this.state.ordersPerPage
                      )
                      .map((row) => (
                        <TableRow
                          key={'order-'+row.url}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          onClick={() => window.open(row.url, '_blank', 'noopener,noreferrer')}
                        >
                          <TableCell align='left'>Placed by: <b>{row.placed_by}</b></TableCell>
                          <TableCell align='center'>Items ordered: <b>{row.items}</b></TableCell>
                          <TableCell align='right'>Submitted: <b>{row.submitted}</b></TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                <button 
                  onClick={() => this.setState({ ordersPage: Math.max(0, this.state.ordersPage - 1) })}
                  disabled={this.state.ordersPage === 0}
                  style={{ marginRight: '1rem' }}
                >
                  Previous
                </button>
                <span>
                  Page {this.state.ordersPage + 1} of{' '}
                  {Math.ceil(this.props.info.orders.length / this.state.ordersPerPage)}
                </span>
                <button 
                  onClick={() => this.setState({ 
                    ordersPage: Math.min(
                      Math.ceil(this.props.info.orders.length / this.state.ordersPerPage) - 1,
                      this.state.ordersPage + 1
                    )
                  })}
                  disabled={this.state.ordersPage >= Math.ceil(this.props.info.orders.length / this.state.ordersPerPage) - 1}
                  style={{ marginLeft: '1rem' }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : null}
        
        {this.props.info.contacts ? (
          <div id="account-contacts" className="info-card expandable">
            <span className="card-header">
              <b>{this.props.info.contacts.length}</b>
              <span>
                {/* Account contact{this.props.info.contacts.length === 1 ? '' : 's'} */}
                {JSON.stringify(this.state.tempContact)}
              </span>
              
              <div className="card-icon-container">
                <ContactMailIcon className="card-icon" />
              </div>
            </span>

            <div className="card-content">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium">

                  <TableBody>
                    {this.props.info.contacts.map((row) => (
                      <TableRow
                        key={'contact-'+row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align='left' onClick={() => this.setState({tempContact: {...row}})}>
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            {this.state.tempContact?.id === row.id ? (
                              <div>
                                <input 
                                placeholder="Name" 
                                onChange={(e) => {
                                  let [fname, ...lname] = e.target.value.split(' ');
                                  this.setState({tempContact: {...this.state.tempContact, fname, lname: lname.join(' ')}})
                                }} 
                                onClick={(e) => e.stopPropagation()}/>
                              </div>
                            ) : (
                              <div>
                                <span style={{color: 'grey'}}>Name: </span>
                                <b>{`${row.fname || ''} ${row.lname || ''}`}</b>
                              </div>
                            )}
                            {this.state.tempContact?.id === row.id ? (
                              <div>
                                <input value={this.state.tempContact?.title || ''} placeholder="Title" onChange={(e) => {
                                  this.setState({tempContact: {...this.state.tempContact, title: e.target.value}})
                                }} onClick={(e) => e.stopPropagation()}/>
                              </div>
                            ) : (
                              <div>
                                <span style={{color: 'grey'}}>Title: </span>
                                <b>{row.title}</b>
                              </div>
                            )}
                          </div>
                          
                        </TableCell>

                        <TableCell align='left' onClick={() => this.setState({tempContact: {...row}})}>
                          <div className="cell-compartment">
                            {this.state.tempContact?.id === row.id ? (
                              <div>
                                <input value={this.state.tempContact?.email || ''} placeholder="Email" onChange={(e) => {
                                  this.setState({tempContact: {...this.state.tempContact, email: e.target.value}})
                                }} onClick={(e) => e.stopPropagation()}/>
                              </div>
                            ) : (
                              <div>
                                <span style={{color: 'grey'}}>Email: </span>
                                <b>{row.email}</b>
                              </div>
                            )}
                            
                            {this.state.tempContact?.id === row.id ? (
                              <div>
                                <input value={this.state.tempContact?.phone || ''} placeholder="Phone" onChange={(e) => {
                                  this.setState({tempContact: {...this.state.tempContact, phone: e.target.value}})
                                }} onClick={(e) => e.stopPropagation()}/>
                              </div>
                            ) : (
                              <div>
                                <span style={{color: 'grey'}}>Phone: </span>
                                <b>{row.phone}</b>
                              </div>
                            )}
                          </div>
                        </TableCell>
                        
                        {this.state.tempContact?.id !== row.id ? (
                          <TableCell align='right'>
                            <div className="cell-compartment">
                              <div>
                                {row.email ? (
                                  <a href={`mailto:${row.email}`}>
                                    <EmailIcon />
                                  </a>
                                ) : null}
                              </div>
                            </div>
                          </TableCell>
                        ) : null}
                      
                        {this.state.tempContact?.id === row.id ? (
                          <TableCell align='right' >
                            <div className="cell-compartment">
                              <DoneIcon className="cell-icon" onClick={() => this.props.updateContact(
                                {...this.state.tempContact, customer_id: this.props.info.id}, 
                                this.props.authToken, () => {
                                  this.setState({tempContact: null})
                                }
                              )}/>

                              <DeleteIcon className="cell-icon" style={{marginTop: 20}} onClick={() => 
                                this.props.updateContact({...row, _destroy: true, customer_id: this.props.info.id}, this.props.authToken, () => {
                                  this.setState({tempContact: null})
                                })}
                              />
                            </div>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              
              <div className='add-new' onClick={() => this.newContact()}>
                <AddIcon style={{color: '#0D74FF', marginRight: 50, zIndex: 1}}/>
                <span>Add new contact</span>
              </div>
            </div>
          </div>
        ) : null}

        <div className="info-card">
          <div className="card-header">
            <b>{this.props.info.files.length}</b>
            <span>File{this.props.info.files.length > 1 ? 's' : ''} uploaded</span>
            <div className="card-icon-container">
              <FileIcon className="card-icon" />
            </div>
          </div>

          <div className="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" className="file-table">
                <TableBody>
                  {this.props.info.files.map((row) => (
                    <TableRow
                      key={'file-'+row.uploaded}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className="file-info-container" align='left'>
                        <p className="file-info file-name">{row.file_name}</p>
                        <p className="file-info file-uploaded"> Uploaded {row.uploaded_formatted} by {row.uploaded_by}</p>
                      </TableCell>
                      {/* <TableCell align='right'>Uploaded {row.uploaded_formatted} by {row.uploaded_by}</TableCell> */}
                      <TableCell align='right' className="file-table-buttons-container">
                        <button className="download-file-button file-table-button" onClick={() => {
                          window.open(row.url, '_blank', 'noopener,noreferrer');
                        }}>
                          <DownloadIcon className="cell-icon download-file-icon" />
                        </button>

                        <button className="share-file-button file-table-button" onClick={() => {
                          // Implement share functionality here
                          // For example, you could copy the file link to clipboard
                          navigator.clipboard.writeText(row.url).then(() => {
                            alert('File link copied to clipboard!');
                          }, (err) => {
                            console.error('Could not copy text: ', err);
                          });
                        }}>
                          <ShareIcon className="cell-icon share-file-icon" />
                        </button>

                        <button className="delete-file-button file-table-button" onClick={() => {
                          if (window.confirm('Are you sure you want to delete this file?')) {
                            this.deleteFile(row.id);
                          }
                        }}>
                          <DeleteIcon className="cell-icon delete-file-icon" />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> 

            <div className="file-drop-container" onClick={(e) => {
              e.stopPropagation();
            }}>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                    this.handleFileSubmit(acceptedFiles[0]);
                  }
                }}
                maxSize={10 * 1024 * 1024} // 10MB
              >
                {({getRootProps, getInputProps, isDragActive}) => (
                  <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                    <input {...getInputProps()} onChange={(e) => this.setState({selectedFile: e.target.files[0]})} />
                    <div className={`add-new ${isDragActive ? 'active' : ''}`}>
                      <UploadFileIcon style={{color: '#0D74FF', marginRight: 50, zIndex: 1}}/>
                      {isDragActive ? <span>Drop the file here ...</span> : <span>Drag file here or click to upload</span>}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>        
        
        {this.props.info.quotes ? (
          <div id="quotes" className="info-card">
            <div className="card-header">
              <b>{this.props.info.quotes.filter(quote => quote.sent_at).length}</b>
              <span>Quote{this.props.info.quotes.filter(quote => quote.sent_at).length === 1 ? '' : 's'} submitted</span>
              <div className="card-icon-container">
                <DescriptionIcon className="card-icon" />
              </div>
            </div>

            <div className="card-content">
              {this.props.info.quotes.length > 0 ? [
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium" className="quote-table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>ID</TableCell> */}
                        <TableCell>Date</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Recipients</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.info.quotes
                        .slice(
                          this.state.quotesPage * this.state.quotesPerPage,
                          (this.state.quotesPage + 1) * this.state.quotesPerPage
                        )
                        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                        .map((row) => (
                          <TableRow key={'quote-'+row.id} onClick={() => this.loadQuote(row.id)}>
                            {/* <TableCell>{row.id}</TableCell> */}
                            <TableCell>{new Date(row.updated_at).toLocaleDateString()}</TableCell>
                            <TableCell>{row.subject || 'No Subject'}</TableCell>
                            <TableCell>{row.recipients?.split(',').length || 0} recipient(s)</TableCell>
                            <TableCell>{row.sent_at ? 'Sent' : 'Draft'}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>,
                <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                  <button 
                    onClick={() => this.setState({ quotesPage: Math.max(0, this.state.quotesPage - 1) })}
                    disabled={this.state.quotesPage === 0}
                    style={{ marginRight: '1rem' }}
                  >
                    Previous
                  </button>
                  <span>
                    Page {this.state.quotesPage + 1} of{' '}
                    {Math.ceil(this.props.info.quotes.length / this.state.quotesPerPage)}
                  </span>
                  <button 
                    onClick={() => this.setState({ 
                      quotesPage: Math.min(
                        Math.ceil(this.props.info.quotes.length / this.state.quotesPerPage) - 1,
                        this.state.quotesPage + 1
                      )
                    })}
                    disabled={this.state.quotesPage >= Math.ceil(this.props.info.quotes.length / this.state.quotesPerPage) - 1}
                    style={{ marginLeft: '1rem' }}
                  >
                    Next
                  </button>
                </div>
              ] : null}

              

              <div className='add-new' onClick={() => this.newQuote()}>
                <AddIcon style={{color: '#0D74FF', marginRight: 50, zIndex: 1}}/>
                <span>Create New Quote</span>
              </div>
            </div>
          </div>
        ) : null}
        
        {this.props.info.contract ? (
          <a href={this.props.info.contract} className="button view-contract" target="_blank" rel="noopener noreferrer">
            <span className="card-header">
              <b>View Contract</b>
              <div className="card-icon-container">
                <DescriptionIcon className="button-icon" />
              </div>
            </span>
          </a>
        ) : null}


        
        {this.props.info.serviceLog ? (
          <div className="button view-log" onClick={() => this.setState({showServiceLogs: true})}>
            <span className="card-header">
              <b>Service Log</b>
              <div className="card-icon-container">
                <BookIcon className="button-icon" />
              </div>
            </span>
          </div>
        ) : null}


        {this.state.showServiceLogs ? (
          <ServiceLogModal 
            logs={this.props.info.serviceLog} 
            close={() => this.setState({showServiceLogs: false})}
          />
        ) : null}
        
        <AccountReviewModal 
          data={this.props.info.account_review}
          year={this.state.accountReviewYear}
          close={() => this.setState({accountReviewYear: null})}
        />

        {this.props.activeQuote ? (
          <QuoteGeneratorModal 
            close={() => this.props.loadQuote(null, null)}
          />
        ) : null}

      </div>
    ) : null
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    info: state.info,
    quotableProducts: state.quotableProducts,
    activeQuote: state.activeQuote
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);