import React from 'react';
import RecipientSelect from '../components/RecipientSelect';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import M from 'materialize-css';
import Confetti from 'react-confetti';


class QuoteGeneratorModal extends React.Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      productSearchText: null,
      showProductSearch: true,
      tempInput: {}, // {'body': {value: 'hello', status: 'saving'}, 'quote_lines.1.model': {value: 'r-123', status: 'editing'}}
      errorFields: [],
      addProductMode: false, // true on mouseover quoteable_product
      confirmDelete: false,
      submittedSuccessfully: false
      // showRecipientSelect: true // for debugging
    }
  }

  componentDidMount() {
    if (!this.props.quotableProducts || !this.props.quote) {
      this.props.newQuote(this.props.authToken, {
        customer_id: this.props.info.id,
        quote_date: new Date().toISOString().split('T')[0]
      });
    }
    // Initialize all textareas
    // M.textareaAutoResize(document.querySelector('.materialize-textarea'));
  }

  componentDidUpdate(prevProps) {
    // Update textarea height when quote body changes
    if (prevProps.quote?.body !== this.props.quote?.body) {
      M.textareaAutoResize(document.querySelector('.materialize-textarea'));
    }
  }

  setTempInput(fieldName, patch) {
    // clear temp input for a field
    let tempInput = {...this.state.tempInput};

    if (!tempInput[fieldName]) {
      return;
    }

    if (patch) {
      tempInput[fieldName] = {...tempInput[fieldName], ...patch};
    } else {
      delete tempInput[fieldName];
    }
    return this.setState({tempInput});
  }

  handleInputBlur(field, line) {
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    let tempInput = this.state.tempInput[fieldName];
    let storedValue = line ? this.props.quote.quote_lines[line.id][field] : this.props.quote[field];

    let quotePatch = line ? {quote_lines_attributes: {[line.id]: {[field]: tempInput.value}}} : {[field]: tempInput.value};
    quotePatch.id = this.props.quote.id;

    if (`${tempInput?.value}` === `${storedValue}`) { // if temp input value matches stored value, clear temp input
      this.setTempInput(fieldName, null);
    } else { // if temp input value doesn't match stored value, update stored value
      this.setTempInput(fieldName, {status: 'saving'});
      this.props.updateQuote(
        this.props.authToken, 
        quotePatch, 
        () => this.setTempInput(fieldName, null),
        () => this.setState({errorFields: [...this.state.errorFields, fieldName]})
      );
    }
  }

  searchResults(value) { // update eventually to use fuse
    return this.props.quotableProducts.filter((product) => 
      product.model.toLowerCase().includes(value.toLowerCase()) ||
      product.description.toLowerCase().includes(value.toLowerCase())
    ).slice(0, 10);
  }

  quotableProductPatch(product) { // fired when user clicks a suggested product
    return {
      quotable_product_id: product.id,
      model: product.model,
      description: product.description,
      price: product.cost,
    }
  }

  addQuotableProduct(line, product) {
    let linePatch = {
      quotable_product_id: product.id,
      model: product.model,
      description: product.description,
      price: product.list_price,
    }

    let quotePatch = {
      id: this.props.quote.id,
      quote_lines_attributes: {[line.id]: linePatch}
    }

    this.props.updateQuote(this.props.authToken, quotePatch, () => {
      this.setTempInput(`quote_lines.${line.id}.model`, null);
    });
  }

  formatPrice(price) {
    if (!price || isNaN(parseFloat(price))) {
      return '0.00';
    }
    let formattedPrice = `${parseFloat(price).toFixed(2)}`;
    console.log('formatted price', price, formattedPrice);
    return formattedPrice;
  }

  handleInputFocus(field, line) {
    let tempInput = {...this.state.tempInput};
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    let value = line ? this.props.quote.quote_lines[line.id][field] : this.props.quote[field];

    if (field === 'price') {
      value = this.formatPrice(value);
    }

    tempInput[fieldName] = {value: value, status: 'editing'};
    this.setState({tempInput: tempInput});
  }

  handleInputChange(field, value, line) {
    let tempInput = {...this.state.tempInput};
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    tempInput[fieldName] = {value: value, status: 'editing'};
    this.setState({tempInput: tempInput});
  }

  handleAdditionalServicesSubmit(fieldName) {
    let tempValue = this.state.tempInput[fieldName]?.value;

    if (!tempValue) {
      return;
    }

    let storedValues = this.props.quote[fieldName] ? this.props.quote[fieldName].split(',') : [];
    
    let concatValues = new Set([...storedValues, tempValue]);
    let joinedValue = Array.from(concatValues).filter(Boolean).join(',') + ',';
    let quotePatch = {id: this.props.quote.id, [fieldName]: joinedValue};

    this.props.updateQuote(
      this.props.authToken, 
      quotePatch, 
      () => this.setTempInput(fieldName, null),
      () => this.setState({errorFields: [...this.state.errorFields, fieldName]})
    );
  }

  removeAdditionalService(service, fieldName) {
    let storedValues = this.props.quote[fieldName] ? this.props.quote[fieldName].split(',') : [];
    let filteredValues = storedValues.filter(value => value !== service);
    let joinedValue = filteredValues.filter(Boolean).join(',') + ',';
    let quotePatch = {id: this.props.quote.id, [fieldName]: joinedValue};
    this.props.updateQuote(this.props.authToken, quotePatch);
  }

  formatLeadTime() {
    let tempLeadTime = this.getFieldValue('lead_time');
    return tempLeadTime ? `Lead time: ${tempLeadTime}` : '';
  }

  getFieldStatus(field, line) {
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    return this.state.tempInput[fieldName]?.status || 'saved';
  }

  getFieldValue(field, line) {
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    let savedValue = line ? this.props.quote.quote_lines[line.id][field] : this.props.quote[fieldName];
    let tempInput = this.state.tempInput[fieldName];
    let value = tempInput ? tempInput.value : savedValue || '';

    // console.log('input value', fieldName, value);
    return value;
  }

  handleRemoveLine(line) {
    let quotePatch = {id: this.props.quote.id, quote_lines_attributes: {[line.id]: {'_destroy': true}}};
    
    this.props.updateQuote(this.props.authToken, quotePatch);

    // clear temp input for line
    Object.keys(line).forEach(key => {
      this.setTempInput(`quote_lines.${line.id}.${key}`, null);
    });
  }

  handleRecipientSelect(email) {
    console.log('handleRecipientSelect', email);
    let recipients = this.state.tempInput['recipients']?.value ? this.state.tempInput['recipients']?.value.split(',') : [];

    // add or remove recipient
    if (recipients.includes(email)) {
      recipients = recipients.filter(r => r !== email);
    } else {
      recipients.push(email);
    }

    // join recipients and update quote
    let joinedRecipients = recipients.filter(Boolean).join(',');
    this.setTempInput('recipients', {value: joinedRecipients, status: 'editing'});
    return joinedRecipients;
  }

  handleRecipientSubmit() {
    let recipients = this.state.tempInput['recipients']?.value || '';
    if (recipients === this.props.quote.recipients) {
      this.setTempInput('recipients', null);
      return;
    } else {
      this.props.updateQuote(
        this.props.authToken, 
        {id: this.props.quote.id, recipients}, // cool trick, effectively {...recipients: recipients}
        () => {
          this.setTempInput('recipients', null);
        },
        () => this.setState({errorFields: [...this.state.errorFields, 'recipients']})
      );
    }
  }

  addNewAccountContact(email) {
    if (!email) {
      return;
    }

    let patch = {
      name: null,
      phone: null,
      title: null,
      primary: false,
      reports: false,
      email: email,
      new: true,
      customer_id: this.props.info.id,
      id: Date.now()
    }

    this.props.updateContact(patch, this.props.authToken, () => {
      return this.handleRecipientSelect(email);
    });
  }

  handleDeleteQuote(e) {
    e.stopPropagation();
    if (this.state.confirmDelete) {
      this.props.deleteQuote(this.props.authToken, this.props.quote.id);
    } else {
      this.setState({confirmDelete: true});

      setTimeout(() => {
        this.setState({confirmDelete: false});
      }, 3000);
    }
  }

  render() {
    if (this.state.submittedSuccessfully) {
      return <div className="modal-backdrop" onClick={() => this.props.close()}>
        <Confetti
          width={window.width}
          height={window.height}
          numberOfPieces={40}
          initialVelocityY={0}     
          recycle={false}
          tweenDuration={10000}
        />
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="success-container">
              <h3>Quote Submitted Successfully!</h3>
              <div className="success-row">
                <button className="close-button" onClick={() => this.props.close()}>Close</button>
                <a href={`https://www.proasysinc.com/quotes/show?id=${this.props.quote.hash_id}`} target="_blank" className="preview-button">
                  Preview Quote
                </a>    
              </div>
            </div>       
          </div>
        </div>
      </div>
    }

    if (!this.props.quotableProducts || !this.props.quote) {
      return <div className="modal-backdrop" onClick={() => this.props.close()}>
        <div className="modal">
          <div className="modal-content">
            <h3>Preparing Quote Wizard...</h3>
          </div>
        </div>
      </div>
    }

    return (
      <div className="modal-backdrop modal-large" onClick={() => this.props.close()}>
        <button className="modal-close" onClick={() => this.props.close()}>Close</button>
        {this.props.quote.sent_at ? null : (
          <button className="quote-delete-button" onClick={(e) => this.handleDeleteQuote(e)}>
            {this.state.confirmDelete ? 'Are you sure?' : 'Delete Draft'}
          </button>
        )}
      
        <div className="modal" onClick={e => e.stopPropagation()}>
          <div className="modal-content lightgrey-background" style={{padding: 0}}>
            <div className="quote-wizard">
              <div className="quote-wizard-watermark"></div>
              <div className="quote-wizard-header">
                <p>{new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                <span>{this.props.info.name}</span><br></br>
                <span>{this.props.info.addresses[0].street}</span><br></br>
                <span>{this.props.info.addresses[0].city}, {this.props.info.addresses[0].state} {this.props.info.addresses[0].zip}</span><br></br>
                <p 
                  className="quote-wizard-header-recipients"
                  onClick={() => this.handleInputFocus('recipients')}
                >
                  To: {this.props.quote.recipients?.split(',').join(', ')}
                </p>
              </div>


              {/* {process.env.NODE_ENV === 'development' && (
                <span style={{color: 'red'}}>{JSON.stringify(this.state.tempInput)}</span>
              )} */}

              <div className="quote-wizard-body">
                {/* <input type="text" placeholder="Subject (Optional)" /> */}
                <form className="quote-form" onSubmit={(e) => {e.preventDefault()}}>
                  <div className="quote-form-row">
                    <input type="text" placeholder="Subject (Optional)" 
                      className={`quote-form-field subject-field ${this.getFieldStatus('subject')}`}
                      onFocus={() => this.handleInputFocus('subject')}
                      onInput={(e) => {
                        e.preventDefault();
                        this.handleInputChange('subject', e.target.value)
                      }}
                      value={this.getFieldValue('subject')}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleInputBlur('subject');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          this.handleInputBlur('subject');
                        }
                      }}
                    />
                  </div>

                  <div className="quote-form-row">
                    <textarea 
                      placeholder="Message"
                      className={`quote-form-field body-field materialize-textarea ${this.getFieldStatus('body')}`}
                      type="text"
                      onFocus={() => this.handleInputFocus('body')}
                      onInput={(e) => {
                        e.preventDefault();
                        this.handleInputChange('body', e.target.value)
                      }}
                      value={this.getFieldValue('body')}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleInputBlur('body');
                      }}
                    />
                  </div>

                  {Object.values(this.props.quote.quote_lines)
                    .sort((a, b) => {
                      if (a.id === 0) return 1;
                      if (b.id === 0) return -1;
                      return 0;
                    })
                    .map((line) => (
                    <div className="quote-form-line">
                      <div className="quote-form-row">
                        <input
                          placeholder="Search for a Product"
                          className={`quote-form-field product-field ${this.getFieldStatus('model', line)}`} 
                          style={{flex: 2}} type="text" 
                          value={this.getFieldValue('model', line)}
                          onFocus={() => this.handleInputFocus('model', line)}
                          onInput={(e) => {
                            e.preventDefault();
                            this.handleInputChange('model', e.target.value, line)
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            if (!this.state.addProductMode) {
                              this.handleInputBlur('model', line);
                            }
                          }}
                        />

                        {line.model && [
                          <input 
                            placeholder="Description" 
                            className={`quote-form-field product-field ${this.getFieldStatus('description', line)}`} 
                            style={{flex: 4}} type="text"
                            value={this.getFieldValue('description', line)}
                            onFocus={() => this.handleInputFocus('description', line)}
                            onInput={(e) => {
                              e.preventDefault();
                              this.handleInputChange('description', e.target.value, line)
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                              this.handleInputBlur('description', line);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                this.handleInputBlur('description', line);
                              }
                            }}
                          />,
                          <input 
                            placeholder="Qty" 
                            className={`quote-form-field product-field centered-text ${this.getFieldStatus('quantity', line)}`} 
                            style={{flex: 1}} type="text"
                            onFocus={() => this.handleInputFocus('quantity', line)}
                            onInput={(e) => {
                              e.preventDefault();
                              this.handleInputChange('quantity', e.target.value, line)
                            }}
                            value={this.getFieldValue('quantity', line)}
                            onBlur={(e) => {
                              e.preventDefault();
                              this.handleInputBlur('quantity', line);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                this.handleInputBlur('quantity', line);
                              }
                            }}
                          />,
                          <input 
                            placeholder="Price" 
                            className={`quote-form-field product-field centered-text ${this.getFieldStatus('price', line)}`} 
                            style={{flex: 2}} type="text" 
                            onFocus={() => this.handleInputFocus('price', {...line, price: '4.20'})}
                            onInput={(e) => {
                              e.preventDefault();
                              this.handleInputChange('price', e.target.value.replace(/^[\$\s]/g, ''), line)
                            }}
                            value={
                              this.state.tempInput[`quote_lines.${line.id}.price`] ? 
                                `$${this.state.tempInput[`quote_lines.${line.id}.price`].value}` : 
                                `$${this.formatPrice(line.price)}`
                            }
                            onBlur={(e) => {
                              e.preventDefault();
                              this.handleInputBlur('price', line);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                this.handleInputBlur('price', line);
                              }
                            }}
                          />
                        ]}
                        {line.id == 0 ? null : (
                          <button 
                            className="quote-form-button remove-product-button"
                            onClick={() => this.handleRemoveLine(line)}
                          >X</button>
                        )}
                      </div>

                      {/* search suggestions */}
                      {this.state.tempInput[`quote_lines.${line.id}.model`]?.value && (
                        <div className="quote-form-row">
                          <div className="product-suggestions-container">
                            {this.searchResults(this.state.tempInput[`quote_lines.${line.id}.model`]?.value).map(product => (
                              <button
                                className="product-suggestion" 
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.addQuotableProduct(line, product);
                                }}
                                onMouseEnter={() => this.setState({addProductMode: true})}
                                onMouseLeave={() => this.setState({addProductMode: false})}
                              >
                              <span className="product-suggestion-model">{product.model}</span>
                              <span className="product-suggestion-description">{product.description}</span>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    </div>
                  ))}

                  {/* <div className="quote-form-row">
                    <button 
                      className="quote-form-button add-product-button"
                      onClick={() => this.newQuoteLine()}
                    >
                      Add Another Product to Quote
                    </button>
                  </div> */}

                  <div className="quote-form-row" style={{marginTop: '30px'}}>
                    <input 
                      placeholder="Additional Services Included" 
                      className={`quote-form-field ${this.getFieldStatus('additional_services_included')}`} 
                      type="text" 
                      style={{flex: 1}}
                      onInput={(e) => {
                        e.preventDefault();
                        this.handleInputChange('additional_services_included', e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ',') {
                          e.preventDefault();
                          this.handleAdditionalServicesSubmit('additional_services_included');
                        }
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleAdditionalServicesSubmit('additional_services_included');
                      }}
                      value={this.state.tempInput['additional_services_included']?.value || ''}
                    />
                    
                    <input 
                      placeholder="Additional Services Excluded" 
                      className={`quote-form-field ${this.getFieldStatus('additional_services_excluded')}`} 
                      type="text" 
                      style={{flex: 1}}
                      onInput={(e) => {
                        e.preventDefault();
                        this.handleInputChange('additional_services_excluded', e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ',') {
                          e.preventDefault();
                          this.handleAdditionalServicesSubmit('additional_services_excluded');
                        }
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleAdditionalServicesSubmit('additional_services_excluded');
                      }}
                      value={this.state.tempInput['additional_services_excluded']?.value || ''}
                    />
                  </div>

                  <div className="quote-form-row">
                    <div className="quote-form-services_container">
                      {this.props.quote['additional_services_included']?.split(',').map((service) => service && (
                        <button 
                          className="quote-form-service"
                          onMouseOver={(e) => e.target.textContent = 'Remove'} 
                          onMouseOut={(e) => e.target.textContent = service}
                          onClick={() => this.removeAdditionalService(service, 'additional_services_included')}
                        >
                          {service}
                        </button>
                      ))}
                    </div>
                    <div className="quote-form-services_container">
                      {this.props.quote['additional_services_excluded']?.split(',').map((service) => service && (
                        <button 
                          className="quote-form-service"
                          onMouseOver={(e) => e.target.textContent = 'Remove'} 
                          onMouseOut={(e) => e.target.textContent = service}
                          onClick={() => this.removeAdditionalService(service, 'additional_services_excluded')}
                        >
                          {service}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="quote-form-row">
                    <input 
                      placeholder="Lead Time" 
                      className={`quote-form-field lead-time-field ${this.getFieldStatus('lead_time')}`}
                      type="text"
                      onFocus={() => this.handleInputFocus('lead_time')}
                      onInput={(e) => this.handleInputChange('lead_time', e.target.value.replace(/^Lead time:\s*/i, ''))}
                      value={this.formatLeadTime()}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleInputBlur('lead_time');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          this.handleInputBlur('lead_time');
                        }
                      }}
                    />
                  </div>
                  
                  <div className="quote-form-row">
                    <span className="quote-form-field-label">Pricing Valid For</span>
                    <input 
                      placeholder="" 
                      className={`quote-form-field short-field valid-days-field ${this.getFieldStatus('valid_days')}`}
                      type="text"
                      onFocus={() => this.handleInputFocus('valid_days')}
                      onInput={(e) => this.handleInputChange('valid_days', e.target.value)}
                      value={this.getFieldValue('valid_days')}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleInputBlur('valid_days');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          this.handleInputBlur('valid_days');
                        }
                      }}
                    />
                    <span className="quote-form-field-label">Days</span>
                  </div>
                </form>
              </div>
              <div className="quote-wizard-footer">
                <i>ProAsys • 318 Hendel St • Shillington, PA 19607-2495 • ph: 610-775-1505 • fax: 610-775-8776</i><br></br>
                <i>proasysinc.com</i>
              </div>
            </div>

            <div className="quote-wizard-buttons-container">
              <a href={`https://www.proasysinc.com/quotes/show?id=${this.props.quote.hash_id}`} target="_blank" className="quote-wizard-button preview-button">
                Preview Quote
              </a>    

              <button 
                className="quote-wizard-button select-recipients-button" 
                onClick={() => {
                  this.handleInputFocus('recipients');
                }}
              >
                Select Recipients
              </button>
            
              <button 
                className={`quote-wizard-button send-button ${this.props.quote.recipients?.length ? '' : 'disabled'}`}
                onClick={() => this.props.submitQuote(this.props.authToken, this.props.quote.hash_id, () => this.setState({submittedSuccessfully: true}))}
              >
                Send Quote
              </button>
            </div>
          </div>
        </div>

        {this.state.tempInput?.['recipients'] && (
          <RecipientSelect 
            contacts={this.props.info.contacts}
            selectedRecipients={this.state.tempInput['recipients']?.value?.split(',')}
            handleRecipientSelect={email => this.handleRecipientSelect(email)}
            addNewContact={contact => this.addNewAccountContact(contact)}
            submit={() => this.handleRecipientSubmit()}
            close={() => this.setState({tempInput: {}})}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    info: state.info,
    quote: state.activeQuote,
    quotableProducts: state.quotableProducts,
    loading: state.loading,
    loadingMessage: state.loadingMessage
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteGeneratorModal);
