import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions
import 'materialize-css';
import '../styles/lookup.scss';
import Search from './Search';
import AccountInfo from './AccountInfo';
import ProductInfo from './ProductInfo';
// import Nav from '../components/Nav';

import preventRoboto from '../PreventRoboto';
//prevent react google maps from loading new roboto font on map load

import Autocomplete from '../components/Autocomplete';

class LookupMain extends React.Component {

  constructor() {
    super();

    this.state = {
      searchList: []
    }
  }

  componentDidMount() {
    console.log('component mounted at ', new Date().toISOString());
    preventRoboto();
    this.props.showNav(true);

    // Check URL parameters for direct navigation
    const urlParams = new URLSearchParams(window.location.search);
    const infoType = urlParams.get('type');
    const infoId = urlParams.get('id');

    // Fetch data sequentially using Promise chaining instead of nested callbacks
    this.props.fetchAccounts(this.props.authToken)
      .then(() => this.props.fetchProducts(this.props.authToken))
      .then(() => {
        this.setState({searchList: this.buildSearchList()});
        if (infoType && infoId) {
          this.props.fetchInfo(this.props.authToken, infoId, infoType, null);
        }
      })
      .then(() => this.props.fetchReportsList(this.props.authToken))
      .then(() => this.props.fetchScheduledServiceList(this.props.authToken))
      .then(() => this.props.fetchRecentlySearched(this.props.authToken))

    // document.getElementById("account-select").focus()
  }

  buildSearchList() {
    let productsList = (this.props.products || []).map(p => ({id: p.id, label: p.model, type: 'p'}));
    let accountsList = (this.props.accounts || []).map(a => ({id: a.id, label: a.name, type: 'a'}));
    console.log(productsList, accountsList);
    return productsList?.concat(accountsList);
  }

  handleOptionSelect(option) {
    this.props.fetchInfo(this.props.authToken, option.id, option.type, null);
  }

  buildRecentlySearchedList(type) {
    let group = type === 'p' ? this.props.products : this.props.accounts;
    let recently_searched_list = (this.props.recentlySearched || []).filter(s => s.startsWith(type));
    return recently_searched_list.map(s => group?.find(item => item.id == s.slice(1)));
  }
  
  render() {
    return (
      <div class="lookup-container">

        <Autocomplete
          list={this.state.searchList}
          onSelect={(option) => this.handleOptionSelect(option)}
          placeholder="Search for an account or product"
          background={true}
        />

        {this.props.info ? null : (
          <div className="lookup-panel-container">  
            <div className="lookup-panel accounts-panel">
              <div className="lookup-panel-contents">
                <div className="lookup-panel-header">
                  <span className="lookup-panel-header-number">{this.props.accounts?.length || 0} </span> Accounts accessible
                </div>
                <div className="lookup-panel-body">
                  <div className="lookup-panel-body-header">Recently searched</div>
                  {this.buildRecentlySearchedList('a').slice(0, 5).map(a => (
                    <button className="recently-searched" onClick={() => this.handleOptionSelect({id: a.id, label: a.name, type: 'a'})}>{a?.name}</button>
                  ))}
                </div>
              </div>
            </div>
            <div className="lookup-panel products-panel">
              <div className="lookup-panel-contents">
                <div className="lookup-panel-header">
                  <span className="lookup-panel-header-number">{this.props.products?.length || 0} </span> Products available
                </div>
                <div className="lookup-panel-body">
                  <div className="lookup-panel-body-header">Recently searched</div>
                  {this.buildRecentlySearchedList('p').map(p => (
                    <button className="recently-searched" onClick={() => this.handleOptionSelect({id: p.id, label: p.model, type: 'p'})}>
                      <span className="recently-searched-product-model">{p?.model} </span> 
                      <span className="recently-searched-product-description">{p?.description}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="lookup-panel">
              <div className="lookup-panel-contents">
                <div className="lookup-panel-header">
                  <span className="lookup-panel-header-number">{this.props.reports?.filter(r => r.submitted).length || 0} </span> Reports submitted this month
                </div>
                <div className="lookup-panel-body">
                  <div className="lookup-panel-body-header">Open drafts</div>
                  {this.props.reports?.filter(r => !r.submitted).reverse().slice(0,5).map(r => (
                    <a href={`/reports/edit?${r.id}`} className="recently-searched">{r?.title}</a>
                  ))}
                </div>
              </div>
            </div>
            <div className="lookup-panel">
              <div className="lookup-panel-contents">
                <div className="lookup-panel-header">
                  <span className="lookup-panel-header-number">{this.props.scheduledService?.length || 0} </span> Service visits scheduled this week
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className={`lookup-landing ${this.props.info ? 'shrunk' : ''}`}>
          <img className="lookup-icon" src="../information_logo.png" alt="Lookup icon"/>
        </div> */}

        <AccountInfo />
        <ProductInfo />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    info: state.info,
    pastSearches: state.pastSearches,
    products: state.products,
    accounts: state.accounts,
    reports: state.reports,
    recentlySearched: state.recentlySearched
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LookupMain);
